<template>
    <!-- 财务报表 —— 发票设置 -->
    <div class="box-card" style="text-align: left">
      <el-form ref="form" :model="form" :rules="formRules" label-width="160px" class="formBox">
        <div class="title">基本信息</div>
        <el-divider></el-divider>
        <el-form-item label="开票平台：" prop="invoice_platform">
          <el-select popper-class="my-select" v-model="form.invoice_platform" placeholder="请选择开票平台" style="width:30%;">
            <el-option label="航信" value="航信"></el-option>
            <!-- <el-option label="百旺" value="百旺"></el-option> -->
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="企业身份代码：" prop="invoice_identity">
              <el-input v-model="form.invoice_identity" style="width:400px;" placeholder="请输入企业身份代码" maxlength="30" @input="(e) => (form.invoice_identity = validSpacing(e))"></el-input>
            </el-form-item> -->
        <el-form-item label="企业身份认证：" prop="invoice_authentication">
          <el-input v-model="form.invoice_authentication" style="width:30%;" placeholder="请输入企业身份认证" maxlength="40" @input="(e) => (form.invoice_authentication = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="可开票时间：" prop="invoice_time">
          <el-input v-model="form.invoice_time" style="width:30%;margin-right:15px;" placeholder="请输入可开票时间" maxlength="3" @input="(e) => (form.invoice_time = validSpacing(e))"></el-input>天后允许开票
        </el-form-item>
        <div class="title">开票商品</div>
        <el-divider></el-divider>
        <div class="invoice_goods_box">
          <el-form-item label="商品名称：" prop="invoice_commodity[0].goodsname">
            <el-input v-model="form.invoice_commodity[0].goodsname" placeholder="请输入商品名称" maxlength="30" @input="(e) => (form.invoice_commodity[0].goodsname = validSpacing(e))"></el-input>
          </el-form-item>
          <el-form-item label="税率：" prop="invoice_commodity[0].taxrate">
            <el-input v-model="form.invoice_commodity[0].taxrate" placeholder="请输入税率" maxlength="8" @input="(e) => (form.invoice_commodity[0].taxrate = validSpacing(e))"></el-input> %
          </el-form-item>
          <el-form-item label="税收分类编码：" prop="invoice_commodity[0].spbm">
            <el-input v-model="form.invoice_commodity[0].spbm" placeholder="请输入税收分类编码" maxlength="30" @input="(e) => (form.invoice_commodity[0].spbm = validSpacing(e))"></el-input>
          </el-form-item>
        </div>
        <div class="title">销售方信息</div>
        <el-divider></el-divider>
        <el-form-item label="销售方税号：" prop="invoice_xfsh">
          <el-input v-model="form.invoice_xfsh" style="width:30%;" placeholder="请输入销售方税号" maxlength="30" @input="(e) => (form.invoice_xfsh = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="销售方电话：" prop="invoice_xfdh">
          <el-input v-model="form.invoice_xfdh" style="width:30%;" placeholder="请输入销售方电话" @input="(e) => (form.invoice_xfdh = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="销售方地址：" prop="invoice_xfdz">
          <el-input v-model="form.invoice_xfdz" style="width:30%;" placeholder="请输入销售方地址" maxlength="30" @input="(e) => (form.invoice_xfdz = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="销售方账户：" prop="invoice_xfyhzh">
          <el-input v-model="form.invoice_xfyhzh" style="width:30%;" placeholder="请输入销售方账户" maxlength="30" @input="(e) => (form.invoice_xfyhzh = validSpacing(e))"></el-input>
        </el-form-item>
        <el-form-item label="开票人设置：" prop="invoice_clerk">
          <el-input v-model="form.invoice_clerk" style="width:30%;" placeholder="请输入开票人" maxlength="30" @input="(e) => (form.invoice_clerk = validSpacing(e))"></el-input>
        </el-form-item>
        <div v-if="isAdmin && comefrom === '1'" class="title">关联停车场</div>
        <el-divider v-if="isAdmin && comefrom === '1'"></el-divider>
        <el-form-item v-if="isAdmin && comefrom === '1'" label="集团：" prop="group_id">
          <el-select popper-class="my-select" clearable v-model="form.group_id" filterable default-first-option placeholder="请选择集团">
            <el-option v-for="item in groupList" :key="item._id" :label="item.group_name" :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="isAdmin && comefrom === '1'" label="停车场：">
          <span>
            {{parking_name}}
          </span>
        </el-form-item>
      </el-form>
      <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm" class="checkButton" @click="fnAddCofig" v-preventReClick>保 存</lbButton>
    </div>
  </template>
<script>
import { addSysInvoiceConfig, getSysInvoiceConfigDetail, updateSysInvoiceConfig, getNoConfigList, getParkingByGroupid } from '@/api/financialStatement'
import { mapGetters } from 'vuex'
export default {
  props: ['id', 'groupId', 'comefrom'],
  data () {
    const checkPhone = (rule, value, callback) => {
      var telReg = new RegExp(/^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/)
      var phoneReg = new RegExp(/^1[3456789]\d{9}$/)
      if ((!telReg.test(value) && !phoneReg.test(value))) {
        callback(new Error('请输入正确的电话号码!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        invoice_platform: '',
        // invoice_identity: '',
        invoice_authentication: '',
        invoice_time: null,
        invoice_commodity: [{
          type: 1,
          goodsname: '',
          taxrate: null,
          spbm: ''
        }],
        invoice_xfsh: '',
        invoice_xfdh: '',
        invoice_xfdz: '',
        invoice_xfyhzh: '',
        invoice_clerk: '',
        group_id: ''
      },
      parking_name: '',
      formRules: {
        invoice_platform: [{ required: true, message: '请选择开票平台', trigger: 'blur' }],
        // invoice_identity: [{ required: true, message: '请输入企业身份代码', trigger: 'blur' }],
        invoice_authentication: [{ required: true, message: '请输入企业身份认证', trigger: 'blur' }],
        invoice_time: [
          { required: true, message: '请输入可开票时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '格式错误，请输入数字', trigger: 'blur' }
        ],
        invoice_commodity: [{
          goodsname: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
          taxrate: [{ required: true, message: '请输入税率', trigger: 'blur' }],
          spbm: [{ required: true, message: '请输入税收分类编码：', trigger: 'blur' }]
        }],
        invoice_xfsh: [{ required: true, message: '请输入销售方税号', trigger: 'blur' }],
        invoice_xfdh: [
          { required: true, message: '请输入销售方电话', trigger: 'blur' },
          {
            validator: checkPhone,
            message: '电话号码格式不正确',
            trigger: 'blur'
          }
        ],
        invoice_xfdz: [{ required: true, message: '请输入销售方地址', trigger: 'blur' }],
        invoice_xfyhzh: [{ required: true, message: '请输入销售方账户', trigger: 'blur' }],
        invoice_clerk: [{ required: true, message: '请输入开票人', trigger: 'blur' }],
        group_id: [{ required: true, message: '请选择集团', trigger: 'change' }]
      },
      isUpdate: false,
      groupList: []
    }
  },
  created () {
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  mounted () {
    // console.log(this.isAdmin);
    if (this.isAdmin) {
      const params = {}
      if (this.groupId.length > 1) {
        params._id = this.groupId
      }
      if (this.comefrom === '1') {
        this.requestGroupNoConfig(params)
      } else if (this.comefrom === '0') {
        this.fngetSysInvoiceConfigDetail()
        // this.form = {
        //   invoice_platform: '航信',
        //   invoice_identity: 'ybkp',
        //   invoice_authentication: '047ba1c5044448088ea8ac247f07ed5f',
        //   invoice_time: 0,
        //   invoice_commodity: [{
        //     type: 1,
        //     goodsname: '停车费',
        //     taxrate: 0.05,
        //     spbm: '3040502020200000000',
        //   }],
        //   invoice_xfsh: '91430100396961659R',
        //   invoice_xfdh: '0731-88925979',
        //   invoice_xfdz: '长沙高新开发区麓天路28号金瑞麓谷科技园1期C11栋302',
        //   invoice_xfyhzh: '66150155200002227',
        //   invoice_clerk: '湖南乐泊科技有限公司',
        //   group_id: '',
        // }
      }
      if (this.id.length > 1) {
        this.fngetSysInvoiceConfigDetail({ _id: this.id })
      }
    } else {
      this.fngetSysInvoiceConfigDetail()
    }
  },
  watch: {
    'form.group_id' (newV, oldV) {
      this.selectGroup(newV)
    }
  },
  methods: {
    async selectGroup (item) {
      if (item) {
        const res = await getParkingByGroupid({ group_id: item })
        if (res && res.Code === 200) {
          this.parking_name = res.Data.parking_name
        }
      } else {
        this.parking_name = '无'
      }
    },
    // 获取发票配置详情
    async fngetSysInvoiceConfigDetail (params) {
      const res = await getSysInvoiceConfigDetail(params)
      // console.log(res);
      if (res && res.Code === 200) {
        if (res.Data) {
          this.form = res.Data
          // this.form.invoice_commodity[0].taxrate = this.form.invoice_commodity[0].taxrate
          this.isUpdate = true
        } else {
          this.isUpdate = false
        }
      }
    },
    // 返回发票管理页面
    // goBack () {
    //   this.$router.go(-1)
    // },
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 表单验证，配置发票
    fnAddCofig () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // console.log(this.form);
          var obj = {}
          this.form.invoice_time = Number(this.form.invoice_time)
          // this.form.invoice_commodity[0].taxrate = Number(this.form.invoice_commodity[0].taxrate) / 100
          const oldObj = this.deepClone(this.form)
          if (this.comefrom === '0') {
            delete oldObj.group_id
          }
          obj = oldObj
          if (this.isUpdate) {
            this.fnUpdateSysInvoiceConfig(obj)
          } else {
            this.fnaddSysInvoiceConfig(obj)
          }
        } else {
          return false
        }
      })
    },
    // 发票配置
    async fnaddSysInvoiceConfig (obj) {
      const res = await addSysInvoiceConfig(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        this.$emit('closeDialog', false)
      }
    },
    // 修改发票配置
    async fnUpdateSysInvoiceConfig (obj) {
      const res = await updateSysInvoiceConfig(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        // this.$router.go(-1)
        this.$emit('closeDialog', false)
      }
    },
    async requestGroupNoConfig (params) {
      const res = await getNoConfigList(params)
      if (res && res.Code === 200) {
        this.groupList = res.Data
      }
    }
  }
}
</script>

  <style scoped lang="less">
  .formBox{
    height: 650px;
    overflow: auto;
  }
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .invoice_goods_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    /deep/ .el-form-item {
      flex: 1;
      .el-input {
        width: 90%;
      }
    }
    /deep/ .el-form-item:nth-child(2) {
      .el-form-item__label {
        width: 150px !important;
      }
      .el-form-item__content {
        margin-left: 150px !important;
      }
    }
    /deep/ .el-form-item:nth-child(3) {
      .el-form-item__label {
        width: 150px !important;
      }
      .el-form-item__content {
        margin-left: 150px !important;
      }
    }
  }
  .box-card {
    position: relative;
    padding-bottom: 30px;
    margin-top: 0!important;
    .checkButton {
        position: absolute;
        right: 0px;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
    }
}
  </style>
