<template>
    <!-- 财务报表 —— 发票详情 -->
    <div class="box-card" style="text-align: left">
      <div class="pdfdiv">
        <el-form v-if="false" ref="detailForm" :model="detailForm" label-width="180px">
          <el-form-item label="停车场：">{{detailForm.consume_address}}</el-form-item>
          <el-form-item label="车牌号：">{{detailForm.plate_number}}</el-form-item>
          <el-form-item label="发票号：">{{detailForm.fpqqlsh}}</el-form-item>
          <el-form-item label="发票金额：">￥ {{detailForm.invoice_cost.toFixed(2)}}</el-form-item>
          <el-form-item label="发票类型：">{{getWayName('InvoiceType', detailForm.consume_type)}}</el-form-item>
          <el-form-item label="纳税人类型：">{{getWayName('TaxpayerType', detailForm.taxpayer_type)}}</el-form-item>
          <el-form-item label="公司名称：">{{detailForm.gfmc}}</el-form-item>
          <el-form-item label="公司税号：">{{detailForm.gfsh}}</el-form-item>
          <el-form-item label="注册地址：">{{detailForm.gfdz}}</el-form-item>
          <el-form-item label="注册电话：">{{detailForm.gfdh}}</el-form-item>
          <el-form-item label="开户行：">{{detailForm.gfyh}}</el-form-item>
          <el-form-item label="银行账号：">{{detailForm.gfyhzh}}</el-form-item>
          <el-form-item label="电子邮箱：">{{detailForm.gf_email}}</el-form-item>
          <el-form-item label="开票时间：">{{ $moment(detailForm.t_kprq).format("YYYY-MM-DD HH:mm:ss") }}</el-form-item>
        </el-form>
        <pdf v-if="show" class="pdfdisplay" ref="pdf" :src="pdfSrc"></pdf>
      </div>
    </div>
  </template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { getSysInvoiceInfoDetail } from '@/api/financialStatement'
import pdf from 'vue-pdf'
export default {
  props: ['id'],
  components: {
    pdf
  },
  data () {
    return {
      detailForm: {},
      show: false
    }
  },
  created () {
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName']),
    pdfSrc () {
      const src = pdf.createLoadingTask({
        url: this.detailForm.invoice_url, // "https://lebopark.oss-cn-shenzhen.aliyuncs.com/upload/assets/202107/xxxx.pdf"
        // url: "https://lebopark.oss-cn-shenzhen.aliyuncs.com/upload/assets/202107/xxxx.pdf",
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',
        cMapPacked: true
      })
      return src
    }
  },
  mounted () {
    var _this = this
    this.$nextTick(() => {
      _this.fngetSysInvoiceInfoDetail()
    })
  },
  methods: {
    // 详情
    async fngetSysInvoiceInfoDetail () {
      const res = await getSysInvoiceInfoDetail({
        _id: this.id
      })
      // console.log(res);
      if (res && res.Code === 200) {
        this.detailForm = res.Data
        this.show = true
      } else {
        this.detailForm = {}
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
    // 返回发票管理页面
    // goBack () {
    //   this.$router.go(-1)
    // }
  }
}
</script>

  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .pdfdiv {
    display: flex;
    align-items: center;
    .pdfdisplay {
      width: 70%;
      object-fit: contain;
      border: 1px solid red;
    }
  }
  </style>
