<template>
  <!-- 财务报表 —— 电子发票 开票记录 -->
  <div class="box-card" style="text-align:left;">
    <!-- 查询表单 -->
    <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-row>
          <el-col :span="24">
            <el-form-item label="开票时间：">
              <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
                start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
              </el-date-picker>
              <!-- <el-date-picker v-model="queryForm.t_kprqStart" @change="onSubmit" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :editable="false" placeholder="请选择开始时间" default-time="00:00:00"></el-date-picker>
            <span style="margin: 0 10px;">~</span>
            <el-date-picker v-model="queryForm.t_kprqEnd" @change="onSubmit" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :editable="false" placeholder="请选择结束时间" default-time="23:59:59"></el-date-picker> -->
            </el-form-item>
            <el-form-item label="发票号：">
              <el-input v-model="queryForm.fpqqlsh" placeholder="请输入发票号" @keyup.enter.native="onSubmit()"
                @input="(e) => (queryForm.fpqqlsh = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select popper-class="my-select" v-model="queryForm.invoiceStatus" placeholder="请选择开票状态" filterable
                clearable default-first-option @change="onSubmit" @clear="clearSelect">
                <el-option v-for="item in getOptions('InvoiceStatus', false)" :key="item.value" :label="item.name"
                  :value="item.value"></el-option>
                <!-- <el-option label="全部" :value="0"></el-option>
              <el-option label="待开票" :value="1"></el-option>
              <el-option label="已开票" :value="1"></el-option>
              <el-option label="开票失败" :value="2"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="停车场：">
              <el-select popper-class="my-select" v-model="queryForm.consume_address" filterable clearable
                default-first-option placeholder="请选择停车场" @change="onSubmit">
                <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
                  :value="item.parking_name">
                </el-option>
              </el-select>
              <!-- <el-input v-model="queryForm.consume_address" placeholder="请输入停车场名称" @input="(e) => (queryForm.consume_address = validSpace(e))"></el-input> -->
            </el-form-item>
            <el-form-item v-if="!isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="isMultiRow">
          <el-col :span="24">
            <el-form-item label="车牌号：">
              <el-input v-model="queryForm.plate_number" @keyup.enter.native="onSubmit()" placeholder="请输入车牌号"
                @input="(e) => (queryForm.plate_number = validPlatetoUpperCase(e))"></el-input>
            </el-form-item>
            <el-form-item v-if="isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </lebo-query>
    <le-card title="开票记录">
      <template slot="heard-right">
        <div class="right">
          <!-- <el-button  size="small" type="danger"  @click="batchDel" >删除</lbButton> -->
          <lbButton type="warning" icon="daochu" @click="exportFile"
            v-if="isShowBtn('1b5c9c42e4f843dc873e9359') || isShowBtn('76e93b4a961d4e52aa5496ea')">导出</lbButton>
          <lbButton type="setting" icon="peizhi2" @click="openInvoiceSet"
            v-if="isShowBtn('b82f7f5438b14204919654a7') || isShowBtn('8286abadf9ac4702bbbea4d2')">{{ isAdmin ?
              "默认发票设置" : "发票设置" }}</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
            @current-change="currentChange">
            <template slot-scope="{row}" slot="invoice_cost">
              ￥{{ row.invoice_cost.toFixed(2) }}
            </template>
            <template slot-scope="{row}" slot="invoice_status">
              {{ getWayName('InvoiceStatus', row.invoice_status) }}
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="t_kprq">
              {{ $moment(row.t_kprq).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
             <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetail(row)"></lbButton>
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="plate_number" label="车牌号"></el-table-column>
          <el-table-column prop="consume_address" label="停车场"></el-table-column>
          <el-table-column prop="fpqqlsh" label="发票号"></el-table-column>
          <el-table-column label="金额">
            <template slot-scope="scope">
              ￥{{ scope.row.invoice_cost.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="invoice_status" label="状态">
            <template slot-scope="scope">
              {{ getWayName('InvoiceStatus', scope.row.invoice_status) }}
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="申请时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="t_kprq" label="开票时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.t_kprq).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetail(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
        <!-- 发票设置 -->
        <lebo-dialog :isShow="showEditInvoiceElement" width="73%" title="发票设置"
          :footerSlot="true" @close="showEditInvoiceElement = false">
          <invoiceSet :id="'0'" :groupId="'0'" :comefrom="'0'" @closeDialog="closeInvoiceElement"
            v-if="showEditInvoiceElement"></invoiceSet>
        </lebo-dialog>
        <!-- 查看发票 -->
        <lebo-dialog :isShow="showDetailInvoiceElement" width="73%" title="查看发票"
          :footerSlot="true" @close="showDetailInvoiceElement = false" closeOnClickModal>
          <invoiceDetail :id="dialogInvoiceDetailId" @closeDialog="closeInvoiceElement" v-if="showDetailInvoiceElement">
          </invoiceDetail>
        </lebo-dialog>
      </div>
    </le-card>

  </div>
</template>

<script>
import { getSysInvoiceInfoList } from '@/api/financialStatement'
import { mapActions, mapState, mapGetters } from 'vuex'
import invoiceSet from '../components/invoiceSet/index.vue'
import invoiceDetail from '../components/invoiceDetail/index.vue'
// import FileSaver from "file-saver";
// import XLSX from "xlsx";
export default {
  components: { invoiceSet, invoiceDetail },
  data () {
    return {
      // 查询表单
      queryForm: {
        // -1:全部 2:开票完成 20:开票中 21:开票成功签章中 22:开票失败 24:开票成功签章失败
        invoiceStatus: null,
        t_kprqStart: null,
        t_kprqEnd: null,
        fpqqlsh: null,
        consume_address: '',
        plate_number: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      timeduan: '',
      // 表格数据
      tableData: [],
      showEditInvoiceElement: false, // 发票设置
      showDetailInvoiceElement: false,
      dialogInvoiceDetailId: '',
      isMultiRow: true,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '车牌号',
            prop: 'plate_number'
          }, {
            label: '停车场',
            prop: 'consume_address'
          }, {
            label: '发票号',
            prop: 'fpqqlsh'
          }, {
            label: '金额',
            prop: 'invoice_cost',
            slot: true
          }, {
            label: '状态',
            prop: 'invoice_status',
            slot: true
          }, {
            label: '申请时间',
            prop: 'create_time',
            slot: true
          }, {
            label: '开票时间',
            prop: 't_kprq',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetSysInvoiceInfoList()
  },
  mounted () {
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.t_kprqStart = newV[0]
        this.queryForm.t_kprqEnd = newV[1]
      } else {
        this.queryForm.t_kprqStart = ''
        this.queryForm.t_kprqEnd = ''
        this.onSubmit()
      }
    }
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName', 'isAdmin'])
  },
  methods: {
    async fnGetSysInvoiceInfoList () {
      const res = await getSysInvoiceInfoList({
        invoiceStatus: this.queryForm.invoiceStatus ? this.queryForm.invoiceStatus : -1,
        t_kprqStart: this.queryForm.t_kprqStart,
        t_kprqEnd: this.queryForm.t_kprqEnd,
        fpqqlsh: this.queryForm.fpqqlsh,
        consume_address: this.queryForm.consume_address,
        plate_number: this.queryForm.plate_number,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      // console.log(res);
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.total = res.Data.TotalCount
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetSysInvoiceInfoList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetSysInvoiceInfoList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetSysInvoiceInfoList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      // console.log(`当前页: ${val}`);
      this.fnGetSysInvoiceInfoList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetSysInvoiceInfoList()
    // },
    // 清除开票状态下拉框
    clearSelect () {
      this.queryForm.invoiceStatus = null
      this.onSubmit()
    },
    // 导出
    async exportFile () {
      const res = await getSysInvoiceInfoList({
        invoiceStatus: this.queryForm.invoiceStatus ? this.queryForm.invoiceStatus : -1,
        t_kprqStart: this.queryForm.t_kprqStart,
        t_kprqEnd: this.queryForm.t_kprqEnd,
        fpqqlsh: this.queryForm.fpqqlsh,
        consume_address: this.queryForm.consume_address,
        plate_number: this.queryForm.plate_number,
        PageSize: 0,
        PageIndex: 0
      })
      // console.log(res);
      // res.Data.DataList.forEach(data => {
      //   data.detail.forEach((item, index) => {
      //     data.amount = item.amount
      //   })
      // })
      const resultList = res.Data.DataList
      if (resultList.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '车牌号',
            '停车场',
            '发票号',
            '金额',
            '状态',
            '申请时间',
            '开票时间'
          ]
          const filterVal = [
            'plate_number',
            'consume_address',
            'fpqqlsh',
            'invoice_cost',
            'invoice_status',
            'create_time',
            't_kprq'
          ]
          const list = resultList
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '发票' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        var map = {}
        this.$set(map, j, v[j])
        return this.formatter(map, { property: j })
        // 为formatter专门为了码表渲染的组件
      })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // console.log(row.invoice_status);
      // console.log(column);
      // 日期处理
      if (column.property === 'create_time' || column.property === 't_kprq') {
        return this.$moment(row[column.property]).format('YYYY-MM-DD HH:mm:ss')
      } else if (column.property === 'invoice_cost') {
        return row.invoice_cost = '￥' + row.invoice_cost
      } else if (column.property === 'invoice_status') {
        return row.invoice_status = this.getWayName('InvoiceStatus', row.invoice_status)
      } else {
        return row[column.property]
      }
    },
    // 发票设置页面
    openInvoiceSet () {
      this.showEditInvoiceElement = true
      // this.$router.push({
      //   path: '/invoice-set/0/0/0'
      // })
    },
    // 查看发票
    lookDetail (row) {
      // 发票状态 0:未开票 2:开票完成  20:开票中 21:开票成功签章中 22:开票失败 24:开票成功签章失败
      // console.log(row.invoice_status)
      if (row.invoice_status !== 2) return this.$msg.info(this.getWayName('InvoiceStatus', row.invoice_status))
      this.showDetailInvoiceElement = true
      this.dialogInvoiceDetailId = row._id
      // this.$router.push({ path: '/invoice-detail', query: { id: row._id } })
    },
    closeInvoiceElement () {
      this.showEditInvoiceElement = false
      this.showDetailInvoiceElement = false
      this.fnGetSysInvoiceInfoList()
    },
    // 下拉查询
    toggleChange (flag) {
      // console.log(flag)
      this.isMultiRow = flag
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
  .queryBox {
    margin-bottom: 20px;
  }
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 500px;
  }
}
// 列表最大高度
/deep/.el-table .el-table__body-wrapper{
  max-height: 490px !important;
}
</style>
